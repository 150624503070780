module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sincerely Eden","short_name":"SE","start_url":"/","background_color":"#fff","theme_color":"#10b981","display":"minimal-ui","icon":"/opt/build/repo/static/assets/sincerely-eden-logo-cropped.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"efd615813aaeeab3280c520668669913"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
